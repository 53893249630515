import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Tilganger`}</h2>
    <p>{`For å kunne sende pull-requester eller opprette issues må du ha en GitHub-bruker.`}</p>
    <h2>{`Pull-requester`}</h2>
    <p>{`Når du har gjort deg ferdig med koden din, er det på tide å be andre se over den. Push koden til Github og opprett en pull request som går mot develop-branchen. Legg til et par reviewere - gjerne tidligere bidragsytere, som ofte har god feedback å komme med. Er du usikker på hvem som bør legges til, spør gjerne på Slack eller se i git-loggen til koden du har endret på. Pull requester må godkjennes av minst to reviewere før de kan merges.`}</p>
    <p>{`Før du lager en pull request kan det være lurt å ta en titt gjennom denne huskelisten:`}</p>
    <ul>
      <li parentName="ul">{`Er koden så konsis og forståelig som mulig?`}</li>
      <li parentName="ul">{`Har du lagt til et test-case som bekrefter at endringen fungerer?`}</li>
      <li parentName="ul">{`Trenger endringen å dokumenteres?`}</li>
    </ul>
    <p>{`Alle kan sende pull-requester fra egne forks av designsystem-repositoryet, men for en mer sømløs opplevelse kan du få `}<a parentName="p" {...{
        "href": "/faq/"
      }}>{`skrivetilgang til repositoryet`}</a>{`.`}</p>
    <h2>{`Versjonskontroll`}</h2>
    <p>{`Commit-meldinger følger `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org/"
      }}>{`Conventional Commits`}</a>{`-formatet. Dette gjør vi for å automatisk kunne generere endringslogger og nye versjonsnumre. På grunn av denne automatiske genereringen er det viktig at hver commit kun endrer en pakke, og setter riktig "scope" (pakke) for hver commit. Innholdet i commit-meldingen er det eneste innholdet vi får i changelogen, så bruk gjerne litt tid på å beskrive hva endringen din gjør og hvorfor.`}</p>
    <p>{`Commit-meldinger består i utgangspunktet av type, scope og description. Ytterligere informasjon kan legges til i en valgfri body og/eller footer.`}</p>
    <ul>
      <li parentName="ul">{`type beskriver hvilken type endring som commites. Vanligst er fix (bugfiks eller tilsvarende) og feat (ny eller endret funksjonalitet). Andre typer er også tillatt og brukes av og til, for eksempel refactor, chore eller docs`}</li>
      <li parentName="ul">{`scope beskriver hvilken av pakkene våre endringen gjelder. Må være omsluttet av parenteser`}</li>
      <li parentName="ul">{`description er et kort sammendrag av endringene`}</li>
      <li parentName="ul">{`body og/eller footer er valgfri, men kan inneholde mer informasjon de øvrige feltene. Legges på ny linje, med en blank linje imellom`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`BREAKING CHANGE:`}</code>{` markerer en endring som innebærer at brukerne av komponenten må ta aktivt stilling til den, for eksempel som følge av at komponenten endrer utseende eller API. Må legges først på en linje i body eller footer, og avsluttes med kolon. Commit-meldinger som inneholder en breaking change genererer en ny major versjon av komponenten det gjelder`}</li>
    </ul>
    <p>{`Formatet kan sammenfattes slik:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`**type(scope):** description

body

footer`}</code></pre></div>
    <p>{`Skal du for eksempel innføre redesignede knapper i ffe-buttons kan commit-meldingen se slik ut:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`feat(ffe-buttons): redesign av knapper

knappene har nå nye farger, fonter og former

BREAKING CHANGE: knapper har helt nytt utseende`}</code></pre></div>
    <h2>{`Styling`}</h2>
    <h3>{`Less`}</h3>
    <p>{`Våre stylesheets er skrevet i `}<a parentName="p" {...{
        "href": "http://lesscss.org/"
      }}>{`Less`}</a>{`, som deretter kompileres til CSS. På tross av mulighetene dette åpner opp for, forsøker vi å ha et nøkternt forhold til hva vi bruker av funksjonalitet for å gjøre koden så leselig og fleksibel som mulig. I fremtiden er det kanskje noe helt annet som blir brukt.`}</p>
    <h3>{`BEM`}</h3>
    <p>{`Vår styling bruker CSS og følger `}<a parentName="p" {...{
        "href": "http://getbem.com/"
      }}>{`BEM-konvensjonen`}</a>{`. Sett deg inn i reglene denne konvensjonen pålegger oss, slik at ny kode følger samme konvensjon som den eksisterende. Alle klassenavn i designsystemet er prefikset med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ffe-`}</code>{`.`}</p>
    <h2>{`JavaScript og React`}</h2>
    <p>{`React-komponentene i FFE har i stor grad automatiserte kodestandardssjekker gjennom linting. Likevel er det et par mønstre vi oppfordrer til å følge for å øke gjenbrukbarheten av en komponent:`}</p>
    <h3>{`Bruk ECMAScript`}</h3>
    <p>{`All koden vår transpileres før den eksporteres. Dette betyr at du trygt kan bruke nye features i JavaScript-språket som ikke støttes i alle nettlesere enda.`}</p>
    <h3>{`TypeScript definition`}</h3>
    <p>{`React-komponentene og alle andre JavaScript-pakkene har typedefinisjoner for TypeScript. Dersom du endrer eksterne metoder eller properties i en slik komponent må `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.d.ts`}</code>{` oppdateres tilsvarende, slik at typene er i sync med JavaScript.`}</p>
    <h3>{`Testing`}</h3>
    <p>{`Vi bruker Jest til testing av komponentene, og oppfordrer alle til å hjelpe med å holde testene oppdaterte og relevante. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      